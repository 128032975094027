.App {
  text-align: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.app-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
}

.app-subcontainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .app-subcontainer {
    flex-direction: column;
    align-items: center;
    gap: 120px;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  align-items: center;
}


.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 600px) {
  .progress-bar {
    display: none;
  }
}

.progress-btn {
  width: 55px;
  height: 15px;
  border-radius: 30px;
  background-color: white;
  box-sizing: border-box;
}

@media print {
  .progress-btn {
    background-color: white !important;
  }
}

.pile-label {
  color: white;
  margin: 0 0 5px 35px;
}

@media screen and (max-width: 600px) {
  .pile-label {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
  }
}

.instructions-container {
  display: flex;
  justify-content: center;
  height: fit-content;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .instructions-container {
    margin: 50px 0px;
  }
}

.instructions-container2 {
  width: 30%;
  min-width: 280px;
  max-width: 360px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pile-container {
  display: flex;
  flex-direction: column;
  width: 42vw;
  min-width: 210px;
  height: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.pile-subcontainer {
  background-color: rgb(68, 68, 88, 1);
  width: 100%;
  height: 100%;
  min-height: 300px;
  border-radius: 30px;
  box-shadow: inset 1px 1px 8px rgba(51,52,63, 1);
  padding: 10px;
  box-sizing: border-box;
}

.pile-values-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(110px,1fr));
  box-sizing: border-box;
  gap: 10px;
}

.deck-container {
  display: flex;
  width: 16vw;
  min-width: 200px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 120px 5px 10px 5px;
}

@media screen and (max-width: 600px) {
  .deck-container {
    margin: -80px 5px -60px 5px;
  }
}

.deck-subcontainer {
  background-color: rgb(68, 68, 88, 1);
  width: 14vw;
  min-width: 160px;
  max-width: 190px;
  height: 200px;
  border-radius: 30px;
  box-shadow: inset 1px 1px 8px rgba(51,52,63, 1);
}

.value-card-container {
  background-color: white;
  width: 14vw;
  min-width: 160px;
  max-width: 190px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.card-btn1 {
  width: 50px;
  height: 100%;
  border-radius: 20px 0 0 20px;
  z-index: 2;
}

.card-btn1:hover {
  width: 50px;
  height: 100%;
  background-color: rgb(255, 0, 0, 0.5);
}

.card-btn2 {
  width: 50px;
  height: 100%;
  border-radius: 0 20px 20px 0;
  z-index: 2;
}

.card-btn2:hover {
  width: 50px;
  height: 100%;
  background-color: rgb(0, 255, 0, 0.5);
}

.card-middle {
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.value-card-container2 {
  background-color: white;
  min-width: 'auto';
  max-width: 170px;
  flex: 1;
  height: 55px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.next-page-btn {
  width: 110px;
  height: 40px;
  background-color: #F8BD26;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 6px;
  top: -80px;
}

@media screen and (max-width: 900px) {
  .next-page-btn {
    right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .next-page-btn {
    right: inherit;
    top: -42px;
  }
}

.final-container {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 0px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
}

.final-subcontainer {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .final-subcontainer {
    flex-direction: column;
  }
}

.final-card-container {
  background-color: rgb(68, 68, 88, 1);
  width: 100%;
  max-width: calc(100% / 6);
  height: 220px;
  border-radius: 30px;
  box-shadow: inset 1px 1px 8px rgba(51,52,63, 1);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 200px;
}

@media screen and (max-width: 600px) {
  .final-card-container {
    min-width: 100%;
  }
}

.final-lower-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 20px;
}

.subvalue-card-container {
  min-width: 100px;
  max-width: 80%;
  flex: 1;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: column;
}

.print-page {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
}

.print-page-h1 {
  margin-top: 0;
  color: white;
}

@media print {
  .print-page-h1 {
    color: black;
  }
}

.table {
  border-collapse: collapse;
  background-color: white;
  width: 90%;
  height: 80%;
  table-layout: fixed;
  word-break: break-word;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .table {
    width: 100%;
  }
}

.table th, .table td {
  border: 1px solid black;
  padding: 4px;
}

.table th:last-child, .table td:last-child {
  width: 18%;
}
